import "./App.css";
import { BannerComponent } from "./components/BannerComponenet";
import { ContactFormComponent } from "./components/ContactFormComponent";
import { FooterComponent } from "./components/FooterComponent";
import { NavbarComponent } from "./components/NavbarComponent";
import { NewsLetterComponent } from "./components/NewsLetterComponent";
import { ProjectComponenet } from "./components/ProjectComponent";
import { SkillComponent } from "./components/SkillComponent";

function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <BannerComponent />
      <SkillComponent />
      <ProjectComponenet />
      <ContactFormComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
