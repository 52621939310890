import { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
// import logo from "../assets/img/logo.svg";
import logo from "../assets/img/BannerBg1.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from "../assets/img/whatsapp.png";

export const NavbarComponent = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    let onScroll = () => {
      if (window.scrollY > 50) setScrolled(true);
      else setScrolled(false);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <div>
      <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
          {/* Navbar Logo */}
          <Navbar.Brand href="#home">
            <img src={logo} alt="logo" style={{ width: "180px" }}></img>
          </Navbar.Brand>
          {/* Toggler */}
          <Navbar.Toggle aria-controls="navbar-collapse" />
          <Navbar.Collapse id="navbar-collapse">
            {/* Nav Link */}
            <Nav className="me-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => setActiveLink("home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="#skills"
                className={
                  activeLink === "skills" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => setActiveLink("skills")}
              >
                Skills
              </Nav.Link>
              <Nav.Link
                href="#projects"
                className={
                  activeLink === "projects"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => setActiveLink("projects")}
              >
                Projects
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/habib-ur-rehman-5776361a4/">
                  <img src={navIcon1} alt=""></img>
                </a>
                <a>
                  <img src={navIcon2} alt=""></img>
                </a>
                <a>
                  <img src={navIcon3} alt=""></img>
                </a>
              </div>

              <span style={{ width: "20vw" }}>
                <a
                  aria-label="Chat on WhatsApp"
                  href="https://wa.me/+923351724336"
                >
                  <img alt="Chat on WhatsApp" src={navIcon4} />
                </a>
              </span>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
