import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";
import headerImg from "../assets/img/Habib.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const BannerComponent = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["React JS", "Vue JS", "Node JS"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <div className={"animate__animated animate__fadeIn"}>
              {/* <span className="tagline">Hi, I'm Habib Ur Rehman</span> */}

              {/* <span className="tagline"> Contact Me: +923351724336</span> */}
              <h1>
                {`Software Engineer `}
                <span
                  className="txt-rotate"
                  data-rotate='["React JS", "Vue JS", "Node JS" ]'
                >
                  <span className="wrap">
                    <br />
                    React JS | Vue JS | Node JS
                  </span>
                </span>
              </h1>
              <p style={{ "text-align": "justify" }}>
                Hi, I'm Habib, a Software Engineer with 2+ year of experience in
                Development. I work on creative projects and have strong skills
                in Vue JS, React JS, Node JS, and API development. I am eager to
                learn more and aim to become an expert Full Stack Developer,
                focusing on both Vue and React. I'm committed to improving my
                skills and always look for new challenges to help me grow. I'm
                excited to use my experience and abilities to drive innovation
                and make a significant impact on future projects. <br />
                <br /> <b>Skills</b>: Vue | React | Node | Express | MongoDB |
                SQL | Apache Charts | Bootstrap | Element UI | Tailwind CSS
              </p>
              {/* <button onClick={() => console.log("connect")}>
                Let’s Connect <ArrowRightCircle size={25} />
              </button> */}
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  {/* <span
                    style={{
                      "justify-content": "center",
                      " align-items": "center",
                      display: "flex",
                    }}
                    className="tagline"
                  >
                    Hi, I'm Habib Ur Rehman
                  </span> */}
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
